import React, { useEffect, useState } from 'react';
import { Box, Grid, Toolbar, Typography, CircularProgress, Button, TextField, Checkbox } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import { API } from '../../utils/Api';
import PrimaryButton from '../../components/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import Images from '../../utils/Images';

function CreateOrder() {
    const token = localStorage.getItem("token");
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [razorpayOrderId, setRazorpayOrderId] = useState('');
    const [cartItems, setCartItems] = useState([]);
    const [userDetails, setUserDetails] = useState(null);
    const [cartSummary, setCartSummary] = useState(null);
    const [isKiosk, setIsKiosk] = useState(false);
    const [error, setError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [outletData, setOutletData] = useState(JSON.parse(localStorage.getItem('outletData')));
    const [kioskUser, setKioskUser] = useState(JSON.parse(localStorage.getItem('kioskUser')));

    const handleInputChange = (event) => {
        setPhoneNumber(event.target.value);
        setError('');
    };

    const handleEmailInputChange = (event) => {
        setEmail(event.target.value);
        setEmailError('');
    };

    const handleIdInputChange = (event) => {
        setRazorpayOrderId(event.target.value);
        setError('');
    };

    const validatePhoneNumber = (number) => {
        const phoneRegex = /^[6-9][0-9]{9}$/; 
        return phoneRegex.test(number);
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const fetchUserCart = async () => {
        if (!validatePhoneNumber(phoneNumber)) {
            setError('Please enter a valid 10-digit phone number.');
            return;
        }
        setLoading(true);
        try {
            const response = await axios.get(`${API.BASE_URL}payment/getUserCartByPhoneNumber`, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: {
                    phoneNumber,
                    ...(isKiosk && { kioskUserEmail: kioskUser?.email, kioskCustomer: true }),
                }
            });
            const cartResponse = response?.data?.data?.response;
            setUserDetails(cartResponse?.user || null);
            setCartSummary(cartResponse?.cart || null);
            setCartItems(cartResponse?.cartItems || []);
            if (!cartResponse?.cartItems?.length) {
                toast.info('No items found in the cart.');
            }
        } catch (error) {
            console.error('Error:', error?.response);
            toast.error(error?.response?.data?.error || 'Please try again.');
            setCartItems([]);
            setCartSummary(null);
            setUserDetails(null);
        } finally {
            setLoading(false);
        }
    };

    const createOrder = async () => {
        const params= {
            "phoneNumber": phoneNumber,
            "paymentMode": "ONLINE",
            "outlet": outletData?._id,
            ...(isKiosk && { "kioskUserEmail": kioskUser?.email, "kioskCustomer": true }),
            // "razorpayOrderId": razorpayOrderId,
            "ordersType": "PICKUP",
            "readyTime": cartSummary?.totalMakingTimeInfo?.data?.readyTime,
            "arrivalTimeDurationInMinutes": parseInt(cartSummary?.totalMakingTimeInfo?.data?.makingTimeDuration?.match(/\d+/)?.[0] || 0, 10)
        }
        try {
            const response = await axios.post(`${API.BASE_URL}payment/createOrderFromPOS`, params,  {
                headers: {
                    Authorization: `Bearer ${token}`
                }, 
            });
            toast.success("Order Created Successfully");
            navigate('/onlineOrders');

        } catch (error) {
            console.error('Error:', error?.response);
            toast.error(error?.response?.data?.error || error?.response?.data?.message || 'Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box className="dashBoard">
            <Toolbar />
            <Grid container justifyContent={'center'}>
                <Grid item md={5} flexDirection={'column'}>
                    <Grid container md={12} justifyContent={'space-evenly'} sx={{ marginTop: '40px' }}>
                        <Grid item md={12}>
                            <Typography sx={{ fontSize: '21px', fontWeight: '500', margin: '16px 0 20px 0px', textAlign: 'center' }}>
                                Create Order
                            </Typography>
                        </Grid>
                        <Grid item md={10}>
                            <TextField
                                fullWidth
                                label="Enter Customer Phone Number"
                                variant="outlined"
                                value={phoneNumber}
                                onChange={handleInputChange}
                                error={!!error}
                                helperText={error}
                                sx={{
                                    backgroundColor: '#fff',
                                    marginBottom: '20px',
                                }}
                            />
                            <Box display="flex" alignItems="center" mb={2} onClick={() => setIsKiosk(!isKiosk)} sx={{ cursor: 'pointer' }}>
                                <Checkbox
                                    checked={isKiosk}
                                    onChange={(e) => setIsKiosk(e.target.checked)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                                <Typography>Kiosk Customer</Typography>
                            </Box>
                            {/* {isKiosk && (
                                <TextField
                                    fullWidth
                                    label="Enter Kiosk User Email"
                                    variant="outlined"
                                    value={email}
                                    onChange={handleEmailInputChange}
                                    error={!!emailError}
                                    helperText={emailError}
                                    sx={{
                                        backgroundColor: '#fff',
                                        marginBottom: '20px',
                                    }}
                                />
                            )} */}
                            <Button
                                sx={{ backgroundColor: '#003049', color: '#fff', padding: '10px 30px 10px 30px', '&:hover': { backgroundColor: '#003049'} }}
                                onClick={fetchUserCart}
                                disabled={loading}
                                startIcon={loading ? <CircularProgress size={20} /> : null}
                            >
                                {loading ? 'Fetching...' : 'Fetch User Cart'}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={6} flexDirection={'column'}  sx={{ marginTop: '35px' }}>
                    {/* User Details */}
                    {userDetails && (
                        <Box sx={{ marginTop: '20px', padding: '10px', border: '1px solid #cecece', borderRadius: '8px' }}>
                            <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>User Details</Typography>
                            <Typography>Name: {userDetails?.name || 'N/A'}</Typography>
                            <Typography>Email: {userDetails?.email || 'N/A'}</Typography>
                            <Typography>Phone: {userDetails?.mobile || 'N/A'}</Typography>
                        </Box>
                    )}

                    {/* Cart Summary */}
                    {cartSummary && (
                        <Box sx={{ marginTop: '20px', padding: '10px', border: '1px solid #cecece', borderRadius: '8px' }}>
                            <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>Cart Summary</Typography>
                            <Typography>Total Taxable: ₹{cartSummary?.totalTaxable}</Typography>
                            <Typography>Total Tax: ₹{cartSummary?.totalTaxAmount}</Typography>
                            <Typography>Total Discount: ₹{cartSummary?.totalDiscount}</Typography>
                            <Typography>Grand Total: ₹{cartSummary?.grandTotal}</Typography>
                        </Box>
                    )}

                    {cartSummary?.coupon && (
                        <Box sx={{ marginTop: '20px', padding: '10px', border: '1px solid #cecece', borderRadius: '8px' }}>
                            <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>Coupon Details</Typography>
                            <Typography>Coupon Code: ₹{cartSummary?.coupon?.couponCode}</Typography>
                            {cartSummary?.coupon?.discountAmount && (
                                <Typography>Total Discount Amount: ₹{cartSummary?.coupon?.discountAmount}</Typography>
                            )}
                            <Typography>Discount price: ₹{cartSummary?.coupon?.discountPrice}</Typography>
                        </Box>  
                    )}

                    {/* Cart Items */}
                    {cartItems.length > 0 && (
                        <Grid container sx={{ marginTop: '30px' }}>
                            <Typography sx={{ fontSize: '18px', marginBottom: '20px', textAlign: 'center' }}>
                                Cart Items
                            </Typography>
                            {cartItems.map((item, index) => (
                                <Grid
                                    key={index}
                                    container
                                    sx={{
                                        border: '1px solid #cecece',
                                        borderRadius: '8px',
                                        padding: '10px',
                                        marginBottom: '10px',
                                    }}
                                >
                                    <Grid item md={6}>
                                        <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                                            {item?.product?.name || 'Unknown Product'}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={3}>
                                        <Typography sx={{ fontSize: '16px' }}>Qty: {item?.quantity}</Typography>
                                    </Grid>
                                    <Grid item md={3}>
                                        <Typography sx={{ fontSize: '16px' }}>₹{item?.grandTotal}</Typography>
                                    </Grid>
                                    {item?.addons?.length > 0 && (
                                        <Grid item md={12}>
                                            <Typography sx={{ fontSize: '14px', fontStyle: 'italic', marginTop: '5px' }}>
                                                Addons: {item.addons.map((addon) => addon?.selectedValue).join(', ')}
                                            </Typography>
                                        </Grid>
                                    )}
                                </Grid>
                            ))}
                            <Grid container sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center', paddingBottom: '12px' }}>
                                <Grid item md={6} sx={{ paddingTop: '16px' }}>
                                    <TextField
                                        fullWidth
                                        label="Enter Payment Order ID"
                                        variant="outlined"
                                        value={razorpayOrderId}
                                        onChange={handleIdInputChange}
                                        error={!!error}
                                        helperText={error}
                                        sx={{
                                            backgroundColor: '#fff',
                                            marginBottom: '20px',
                                        }}
                                    />
                                </Grid>
                                <Grid item md={3}>
                                    <PrimaryButton onClick={createOrder} disabledTitle={'Create Order'} title={'Create Order'} style={{ borderRadius: '30px' }} />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}

                    {/* No Cart Items */}
                    {cartItems.length === 0 && !loading && (
                        <Box sx={{ textAlign: 'center' }}>
                        <img style={{ width: '50%' }} src={Images.emptyCart} />
                        <Typography sx={{ textAlign: 'center', marginTop: '10px' }}>
                            No items in the cart.
                        </Typography>
                        </Box>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
}

export default CreateOrder;
